import { Component } from "react";
import { Row,Col, Button } from "react-bootstrap";

class Owners extends Component {
    render(){
        return <><div style={{ display: 'block', width: 'auto', padding: 40 }} className="owner mx-5 text-danger section scrollspy">
        <h2 className="mt-5 display-1">Teams</h2>
        <hr className="my-1 garis-rapi" />
        <p>Our Teams and Founders</p>
        <Row className="py-4 px-5 margin-botop">
            <Col claassName='col-md-6'>
                <img src="https://media.licdn.com/dms/image/v2/D5603AQH2IieAsld-2g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1720973098984?e=1729728000&v=beta&t=dufpj2HTNeeL1NlRygVCnKVkGDwdESyhv7zKdwjx4uA" className="gambar-round img-fluid" />
                <h3 className="mt-3 display-5">Yudha P</h3>
                <hr className="garis-rapi-2"/>
                <p>Project Manager, Full Stack Developer and Penetration Tester</p>
                <a href="https://www.linkedin.com/in/yudha-primadiansyah-62677586/" className="btn btn-danger btn-lg mb-3">Connect with Me</a>
            </Col>
            <Col className="col-md-6  border-left">
                <img src="https://media.licdn.com/dms/image/v2/C5603AQFbTBDaOd3iHw/profile-displayphoto-shrink_100_100/profile-displayphoto-shrink_100_100/0/1660497322213?e=1729728000&v=beta&t=OPijaS9I6OBjR9qW4Au7GMkiZQ74oBcim6X4JPLYFU8" className="gambar-round img-fluid" />
                <h3 className="mt-3 display-5">Dwi Galih R</h3>
                <hr className="garis-rapi-2"/>
                <p>Full Stack Developer, IoT Developer and System Analyst</p>
                <a href="https://www.linkedin.com/in/dwi-galih-ramadhan/" className="btn btn-danger btn-lg">Connect with Me</a>
            </Col>
        </Row></div></>
    }
}

export default Owners