import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import Carousel from 'react-bootstrap/Carousel';
import banner from './assets/banner_pentest_desktop.png';
import banner_mobile from './assets/banner_pentest_mobile.png';

function Portofolio_Sec() {
  return (
    <div className="bg-danger our-layout">
    <Carousel>
      <Carousel.Item>
      <picture>
        <source srcset={banner_mobile} media="(max-width: 450px)"/>
        <img
          src={banner}
          alt="First slide"
          className="banner-mobile img-fluid"
        />
      </picture>
      </Carousel.Item>
   \\
    </Carousel>
    </div>
  );
}

export default Portofolio_Sec;