import { Component } from "react";
import { Col,Card,Row } from "react-bootstrap"
import { AiFillSecurityScan,AiFillCode } from "react-icons/ai";
import { BsFillPeopleFill } from "react-icons/bs";

class Services extends Component {
    render(){
        return <>
        <div style={{ display: 'block', width: 'auto', padding: 40 }} className="mx-5 my-5 text-danger section scrollspy services" >
        <h2 className="mt-5 mb-2 display-1">Services</h2>
        <hr className="my-1 garis-rapi"/>
        <p className="mb-5">We Provide Best Service for Our Clients</p>
    
        
        <Row className="py-1 px-5 mx-auto mt-5 text-danger">
            <Col className="col-md-2 py-5 mx-auto bg-danger">
                <AiFillSecurityScan color="white" size="8em"/>
            </Col>
            <Col className="col-md-10 py-5 px-5 bg-default text-start text-wrap">
                <h2 className="display-5">Security</h2>
                <p>Provide IT security services such as application penetration testing, vulnerability assessment, and code review. Our company has the commitment to provide the best it security services by using the best methodologies provided by well-known cyber security organizations.</p>
            </Col>
        </Row>

        <hr className="my-1 garis-rapi"/>

        <Row className="py-1 px-5 mx-auto my-auto text-danger">
            <Col className="col-md-2 py-5 mx-auto bg-danger">
                <AiFillCode color="white" size="8em"/>
            </Col>
            <Col className="col-md-10 py-5 px-5 bg-default text-start text-wrap">
                <h2 className="display-5">Application</h2>
                <p>Provide application development services for various platforms, such as mobile, web, and desktop. Our company also supports building cross-platform applications. Our team is dedicated to developing applications that are efficient, comprehensive, secure, and comfortable in order to meet the needs of our clients. </p>
            </Col>
            
        </Row>
        
        <hr className="my-1 garis-rapi"/>

        <Row className="py-1 px-5 mx-auto my-auto text-danger">
            <Col className="col-md-2 py-5 mx-auto bg-danger">
            <BsFillPeopleFill color="white" size="8em"/>
            </Col>
            <Col className="col-md-10 py-5 px-5 bg-default text-start text-wrap">
                <h2 className="display-5">Consultancy</h2>
                <p>Provide consulting services to help you find solutions to your digital integration problems and needs. Support your digital implementation to work effective and efficient.</p>
            </Col>
        </Row>

            {/* <Card className="bg-danger text-white">
                    <Card.Body>
                        <Card.Title>Security</Card.Title>
                        <Card.Subtitle className="mb-2 text-muted"><AiFillSecurityScan color="white" size="5em"/></Card.Subtitle>
                        <Card.Text>
                        We Provide IT Security Services such as Application Penetration Testing, Vulnerability Assesment and Code Review. We Have Commitment to Provide Best IT Security Services by Implementing Comprehensive, Detail, Smart, Clever Techniques and High Quality Standarization while Testing the System
                        </Card.Text>
                    </Card.Body>
                </Card> */}
            {/* <Col className="mt-3">
                <Card className="bg-danger text-white h-100 py-3">
                    <Card.Body>
                        <Card.Title>Application</Card.Title>
                        <Card.Subtitle className="mb-2 text-muted"><AiFillCode color="white" size="5em"/></Card.Subtitle>
                        <Card.Text ClassName="text-start">
                            We Provide Services in Application Development for various platforms, such as Mobile, Web and Desktop. We Also Support to build Cross Platform Application. We Have Commitment to Build Efficient, Comprehensive, Secure and Comfort Application to Fill the Client Needs
                        </Card.Text>
                    </Card.Body>
                </Card>
            </Col>
            <Col className="mt-3">
                <Card className="bg-danger text-white h-100 py-3">
                    <Card.Body>
                        <Card.Title>Consultancy</Card.Title>
                        <Card.Subtitle className="mb-2 text-muted"><BsFillPeopleFill color="white" size="5em"/></Card.Subtitle>
                        <Card.Text>
                            We Provide Consultancy Service to Draw and determine system you needs
                        </Card.Text>
                    </Card.Body>
                </Card>
            </Col> */}
        </div></>
    }
}

export default Services