import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import Carousel from 'react-bootstrap/Carousel';
import banner1 from './assets/banner_apps_desktop.png';
import banner2 from './assets/banner_apps_desktop_2.png';
import banner_mobile from './assets/banner_apps_mobile.png';
import banner_mobile_2 from './assets/banner_apps_mobile_2.png';

function Portofolio_App() {
  return (
    <div className="bg-danger our-layout">
    <Carousel>
      <Carousel.Item>
      <picture>
        <source srcset={banner_mobile} media="(max-width: 450px)"/>
        <img
          src={banner1}
          alt="First slide"
          className="banner-mobile img-fluid"
        />
      </picture>
      </Carousel.Item>
      <Carousel.Item>
      <picture>
        <source srcset={banner_mobile_2} media="(max-width: 450px)"/>
        <img
          src={banner2}
          alt="First slide"
          className="banner-mobile img-fluid"
        />
      </picture>
      </Carousel.Item>
    </Carousel>
    </div>
  );
}

export default Portofolio_App;