import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Row, Col } from 'react-bootstrap';
import Carousel from 'react-bootstrap/Carousel';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';


function Research() {
    return (
        <div style={{ display: 'block', width: 'auto', padding: 40 }} className="section scrollspy">
            <h2 className="mt-5 mb-2 display-1 text-danger">Researches</h2>
            <hr className="my-1 garis-rapi text-danger"/>
            <p className="text-danger">Our Research Projects</p>
                <Row className="Row-research px-5 py-2 margin-botop mx-5 my-2">
                    <Col class="col-sm flex-wrap">
                        <Card className="mt-2 bg-danger text-white border border-2 border-danger h-100">
                            <Card.Img variant="top" src="https://miro.medium.com/max/720/1*8pHyKoRzD2F9qN652XcRag.png" height='50%' />
                            <Card.Body>
                                <Card.Title className="judul">Application and Network Protocol Vulnerability</Card.Title>
                                <Card.Text>
                                We are constantly researching potential application and protocol vulnerabilities in new technologies. and stay informed about any threats that may exist in the real world 
                                </Card.Text>
                    
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col class="col-sm flex-wrap ">
                        <Card className="mt-2 bg-danger text-white  border border-2 border-danger h-100 ">
                            <Card.Img variant="top" src="https://global-uploads.webflow.com/6100d0111a4ed76bc1b9fd54/62a1675ad33d01d0f9dedfc8_deep%20learning%204.jpg" height='50%' />
                            <Card.Body>
                                <Card.Title className="judul">Machine Learning-Based Web Application Firewall</Card.Title>
                                <Card.Text>
                                We conduct research on Next-Gen Web Application Firewalls for academic and scientific purposes.
                                </Card.Text>
                            
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col class="col-sm  flex-wrap">
                        <Card className="mt-2 bg-danger text-white  border border-2 border-danger h-100">
                            <Card.Img variant="top" src="https://www.6gflagship.com/wp-content/uploads/6g-flagship-visible-light-communication-1-750x421.jpg" height='50%'/>
                            <Card.Body>
                                <Card.Title>Custom Networking and Security Protocols</Card.Title>
                                <Card.Text>
                                We perform research on developing custom networking and security protocols for particular needs.
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
              
                    




        </div>
    );
}

export default Research;