import { Component } from "react";
import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import image from './assets/logo.png';
import { AiOutlineTwitter, AiFillInstagram, AiFillYoutube } from "react-icons/ai";
import { HiMail} from "react-icons/hi";
import { RiWhatsappFill} from "react-icons/ri";


class About extends Component {
    render() {
        return <div>
            <Row className='about bg-danger px-5 py-5'>
                <Col><img src={image} width='100em' /></Col>
                <Col>
                   <HiMail size={30} color="white" /><br />
                    <p className="text-white text-sm">
                        yudha@foxhoundpanthera.com <br />
                        galih@foxhoundpanthera.com <br />
                    </p>
                </Col>
                <Col>
                    <RiWhatsappFill size={30} color="white" /><br />
                    <p className="text-white text-sm">
                        +62 85780774941
                    </p>
                </Col>
                <Col className="px-4">
                    <AiOutlineTwitter className="mx-2" size={30} color="white" />
                    <AiFillInstagram className="mx-2" size={30} color="white" />
                    <AiFillYoutube className="mx-2" size={30} color="white" /> <br />
                    <p className="text-white text-sm">
                        @foxhound.panthera <br />
                    </p>
                </Col>
            </Row>
        </div>
    }
}

export default About