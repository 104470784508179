import { Nav, NavbarBrand,Container,Navbar,NavDropdown } from "react-bootstrap"
import { Component } from "react"
import logo from "./assets/logo_header.png"

class Menu extends Component {

    constructor(props){
      super(props);
    }

    render(){
        return <Navbar id="menu" collapseOnSelect expand="lg" bg={this.props.background} variant="dark" className="fixed-top">
        <Container className="my-2">
          <Navbar.Brand href="#welcome" className="display-5">
            <img height="30" width="60" className="mx-4" src={logo} /></Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav" className="justify-content-end">
            <Nav>
              {/* <Nav.Link href="#about">About</Nav.Link> */}
              <Nav.Item className="bg-transparent">
                <Nav.Link href="#services" active={true}>Services</Nav.Link>
              </Nav.Item>
              {/* <Nav.Item className="bg-transparent">
                <Nav.Link href="#products" active={true}>Products</Nav.Link>
              </Nav.Item> */}
              <Nav.Item className="bg-transparent">
                <Nav.Link href="#portofolio" active={true}>Portofolio</Nav.Link>
              </Nav.Item>
              <Nav.Item className="bg-transparent">
                <Nav.Link href="#researches" active={true}>Researches</Nav.Link>             
              </Nav.Item>            
              <Nav.Item className="bg-transparent">
                <Nav.Link href="#team" active={true}>Teams</Nav.Link>
              </Nav.Item>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
        // return (<></>)
        
    }

    
}

export default Menu