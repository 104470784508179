import 'bootstrap/dist/css/bootstrap.min.css';
import { Container,Row } from 'react-bootstrap';
import './App.css';
import './Customs.css';
import Menu from './components/Menu'
import Welcome from './components/Welcome'
import Services from './components/Services'
import Technology from './components/Technology';
import Owners from './components/Owners'
import Portofolio from './components/Portofolio';
import Footer from './components/Footer';
import Research from './components/Research';
import Products from './components/Products';
import About from './components/About';
import { Component } from 'react';
import { DiPhonegap } from 'react-icons/di';
import { BsWhatsapp, BsWindowSidebar } from 'react-icons/bs';

class App extends Component {
 
  constructor(props){
    super(props);
    this.state = {
      current_element_id: "welcome",
      background: "default"
    }
     this.changeActiveElement = this.changeActiveElement.bind()
  }

  changeNavbar = () => this.setState({background: document.documentElement.scrollTop > 0 ? "danger":"default"})
  changeActiveElement = (elem) => this.setState({current_element_id: elem});
  render(){
    window.addEventListener("scroll", this.changeNavbar);
    return (
      <><div className="App" onScroll={this.changeNavbar}>
        <Menu background={this.state.background} aktif={"Foxhound Panthera"} />
        <div data-spy="scroll" data-target="#menu" data-offset="0">
          <Container fluid>
            <Welcome />
            <section id="services">
              <Services />
            </section>
            {/* <section id="products">
              <Products />
            </section> */}
            <section id="portofolio">
              <Portofolio />
            </section>
            <section id="researches">
              <Research />
            </section>
            <section id="team">
              <Owners />
            </section>
            <Technology />
            <About />
          </Container>
        </div>
        <Footer />
        <a href="#" className="float">
          <div class="bg-danger box"><a href="https://wa.me/+6285780774941">
            <BsWhatsapp size="3em" color='white' /></a>
          </div>
        </a>
      </div></>
    );
  }

  
}



export default App;
