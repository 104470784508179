import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Row, Col } from 'react-bootstrap';
import Carousel from 'react-bootstrap/Carousel';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import gambar from './assets/produtcs_1.png'


function Products() {
    return (
        <div style={{ display: 'block', width: 'auto', padding: 40 }} className="my-5">
            <h2 className="mt-5 mb-2 display-1 text-danger">Products</h2>
            <hr className="my-1 garis-rapi text-danger"/>
            <p className="text-danger"> Our current products that might help your works</p>
                <Row className="Row-products py-4 px-5 mx-5 justify-content-center">
                    <Col className="col-md-6 ">
                        <Card className="bg-danger text-white border border-2 text-center border-danger">
                        <Carousel>
                            <Carousel.Item interval={1000}>
                                <img
                                src={gambar}
                                className="img-fluid"
                                />
                            </Carousel.Item>
                            <Carousel.Item interval={1000}>
                                <img
                                src={gambar}
                                className="img-fluid"
                                />
                            </Carousel.Item>
                             <Carousel.Item interval={1000}>
                                <img
                                src={gambar}
                                className="img-fluid"
                                />
                            </Carousel.Item>
                        </Carousel>
                        <hr className="my-1 garis-rapi text-danger"/>
                            <Card.Body>
                                <Card.Title>APP C DESA</Card.Title>
                                <Card.Text>
                                This application is intended to make it easier to collect letter c data into digital form and manage it properly
                                </Card.Text>
                                {/* <Button variant="light">Go to Products</Button> */}
                            </Card.Body>
                        </Card>
                    </Col>
                    
                </Row>
              
                    




        </div>
    );
}

export default Products;