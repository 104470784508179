import { Component } from "react";
import { Row,Col,Tabs,Tab, Nav } from "react-bootstrap"
import Portofolio_App from "./Portofolio_App";
import Portofolio_Sec from "./Portofolio_Sec";

class Portofolio extends Component {
    render(){
        return <><div style={{ display: 'block', width: 'auto', padding: 40 }} className="mx-5 my-5 text-danger row-width section scrollspy" id="portofolio" >
         <h2 className="mt-5 mb-2 display-1">Portofolio</h2>
            <hr className="my-1 garis-rapi"/>
            <p>Our Past Projects</p>
        <Row className="Row-portofolio">   
            </Row><Row className="justify-content-md-center ">
                <Col>
                    {/* <Tabs
                        defaultActiveKey="apps"
                        id="fill-tab-example"
                        className="mb-3 border-danger"
                        fill
                    >
                        <Tab eventKey="apps" title="Apps" className="border-danger">
                            <Portofolio_App />
                        </Tab>
                        <Tab eventKey="security" title="Security" className="border-danger">
                            <Portofolio_Sec />
                        </Tab>
                    </Tabs> */}
                    <Tab.Container defaultActiveKey="first">
                    <Row>
                    <Col>
                        <Nav  className="mb-2 nav-pills red">
                            <Col>
                                <Nav.Item>
                                <Nav.Link eventKey="first" className="nav-item">Application</Nav.Link>
                                </Nav.Item>
                            </Col>
                            <Col>
                                <Nav.Item>
                                <Nav.Link eventKey="second" className="nav-item">Security</Nav.Link>
                                </Nav.Item>
                            </Col>
                        </Nav>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                        <Tab.Content>
                            <Tab.Pane eventKey="first">
                            <Portofolio_App />
                            </Tab.Pane>
                            <Tab.Pane eventKey="second">
                             <Portofolio_Sec />
                            </Tab.Pane>
                        </Tab.Content>
                        </Col>
                    </Row>
                    </Tab.Container>
                </Col>
            </Row></div></>;
    }
}

export default Portofolio;