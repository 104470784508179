import { Component } from "react";
import { Button,Row } from "react-bootstrap";

class Welcome extends Component {
    render(){ 
        return (<><Row className="welcome min-vh-100 coverweb" id="welcome">
            <div className="py-auto my-auto text-center text-white">
                <h1 className="header display-2 my-3">FOXHOUND PANTHERA INNOVATION</h1>
                <p className="my-4">Provide Services to Support your Digital Transformation Effectively, Secure, Smart and Affordable
                    <br />Work Fast, Efficient, Well-Managed and Secure
                </p>
                <a href="https://wa.me/+6285780774941" className="btn btn-danger mx-2 btn-lg my-2 welcome-btn">Contact Us</a>
            </div>
        </Row></>)
    }
}

export default Welcome