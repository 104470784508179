import { Component } from "react"
import { Col,Row,Card } from "react-bootstrap"
import { DiAndroid, DiAngularSimple, DiAws, DiCisco, DiCodeigniter, DiDjango, DiDotnet, DiGithub, DiGo, DiJava, DiJsBadge, DiLaravel, DiLinux, DiMysql, DiNginx, DiNodejs, DiPhp, DiPostgresql, DiPython, DiRasberryPi, DiReact, DiRuby, DiUbuntu } from "react-icons/di";
import { SiApache, SiArduino, SiAssemblyscript, SiCloudflare, SiDocker, SiDotnet, SiFlutter, SiKeras, SiKubernetes, SiOracle, SiOwasp, SiSocketdotio, SiSpring, SiTypescript, SiVuedotjs } from "react-icons/si"
class Technology extends Component {
    render(){
        return <> <div style={{ display: 'block', width: 'auto', padding: 40 }} id="technology" className="technology"><h2 className="mt-5 display-3 text-danger">Technologies and Frameworks</h2>
        <hr className="my-1 garis-rapi text-danger"/>
        <p className="text-danger">Our Technologies and Frameworks Integration</p>
        <Row className="Row-tech py-4 px-5 mx-5">
            <Col className="Col-tech" >
                <Card className="bg-danger text-white">
                    <Card.Body>
                        <DiPhp className="text-center" color="white" size="5em"/>
                    </Card.Body>
                </Card>
            </Col>
            <Col className="Col-tech">
                <Card className="bg-danger text-white">
                    <Card.Body>
                        <DiDjango className="text-center" color="white" size="5em"/>
                    </Card.Body>
                </Card>
            </Col>
            <Col className="Col-tech">
            <Card className="bg-danger text-white">
                    <Card.Body>
                        <DiPython className="text-center" color="white" size="5em"/>
                    </Card.Body>
                </Card>
            </Col>
            <Col className="Col-tech">
                <Card className="bg-danger text-white">
                    <Card.Body>
                        <DiNodejs className="text-center" color="white" size="5em"/>
                    </Card.Body>
                </Card>
            </Col>
            <Col className="Col-tech">
                <Card className="bg-danger text-white">
                    <Card.Body>
                        <DiLaravel className="text-center" color="white" size="5em"/>
                    </Card.Body>
                </Card>
            </Col>
            <Col className="Col-tech">
                <Card className="bg-danger text-white">
                    <Card.Body>
                        <DiJsBadge className="text-center" color="white" size="5em"/>
                    </Card.Body>
                </Card>
            </Col>
        </Row>
        <Row className="Row-tech py-4 px-5 mx-5">
            <Col className="Col-tech">
                <Card className="bg-danger text-white">
                    <Card.Body>
                        <DiDotnet className="text-center" color="white" size="5em"/>
                    </Card.Body>
                </Card>
            </Col>
            <Col className="Col-tech">
                <Card className="bg-danger text-white">
                    <Card.Body>
                        <DiPostgresql className="text-center" color="white" size="5em"/>
                    </Card.Body>
                </Card>
            </Col>
            <Col className="Col-tech">
                <Card className="bg-danger text-white">
                    <Card.Body>
                        <DiReact className="text-center" color="white" size="5em"/>
                    </Card.Body>
                </Card>
            </Col>
            <Col className="Col-tech">
                <Card className="bg-danger text-white">
                    <Card.Body>
                        <DiMysql className="text-center" color="white" size="5em"/>
                    </Card.Body>
                </Card>
            </Col>
            <Col className="Col-tech">
                <Card className="bg-danger text-white">
                    <Card.Body>
                        <DiGo className="text-center" color="white" size="5em"/>
                    </Card.Body>
                </Card>
            </Col>
            <Col className="Col-tech">
                <Card className="bg-danger text-white">
                    <Card.Body>
                        <DiJava className="text-center" color="white" size="5em"/>
                    </Card.Body>
                </Card>
            </Col>
        </Row>
        <Row className="Row-tech py-4 px-5 mx-5">
            <Col className="Col-tech">
                <Card className="bg-danger text-white">
                    <Card.Body>
                        <DiAws className="text-center" color="white" size="5em"/>
                    </Card.Body>
                </Card>
            </Col>
            <Col className="Col-tech">
                <Card className="bg-danger text-white">
                    <Card.Body>
                        <DiLinux className="text-center" color="white" size="5em"/>
                    </Card.Body>
                </Card>
            </Col>
            <Col className="Col-tech">
                <Card className="bg-danger text-white">
                    <Card.Body>
                        <DiUbuntu className="text-center" color="white" size="5em"/>
                    </Card.Body>
                </Card>
            </Col>
            <Col className="Col-tech">
                <Card className="bg-danger text-white">
                    <Card.Body>
                        <DiNginx className="text-center" color="white" size="5em"/>
                    </Card.Body>
                </Card>
            </Col>
            <Col className="Col-tech">
                <Card className="bg-danger text-white">
                    <Card.Body>
                        <DiCisco className="text-center" color="white" size="5em"/>
                    </Card.Body>
                </Card>
            </Col>
            <Col className="Col-tech">
                <Card className="bg-danger text-white">
                    <Card.Body>
                        <DiRasberryPi className="text-center" color="white" size="5em"/>
                    </Card.Body>
                </Card>
            </Col>
        </Row>
        <Row className="Row-tech py-4 px-5 mx-5">
            <Col className="Col-tech">
                <Card className="bg-danger text-white">
                    <Card.Body>
                        <DiAndroid className="text-center" color="white" size="5em"/>
                    </Card.Body>
                </Card>
            </Col>
            <Col className="Col-tech">
                <Card className="bg-danger text-white">
                    <Card.Body>
                        <DiCodeigniter className="text-center" color="white" size="5em"/>
                    </Card.Body>
                </Card>
            </Col>
            <Col className="Col-tech">
                <Card className="bg-danger text-white">
                    <Card.Body>
                        <DiRuby className="text-center" color="white" size="5em"/>
                    </Card.Body>
                </Card>
            </Col>
            <Col className="Col-tech">
                <Card className="bg-danger text-white">
                    <Card.Body>
                        <DiGithub className="text-center" color="white" size="5em"/>
                    </Card.Body>
                </Card>
            </Col>
            <Col className="Col-tech">
                <Card className="bg-danger text-white">
                    <Card.Body>
                        <SiVuedotjs className="text-center" color="white" size="5em"/>
                    </Card.Body>
                </Card>
            </Col>
            <Col className="Col-tech">
                <Card className="bg-danger text-white">
                    <Card.Body>
                        <SiApache className="text-center" color="white" size="5em"/>
                    </Card.Body>
                </Card>
            </Col>
        </Row>
        <Row className="Row-tech py-4 px-5 mx-5">
            <Col className="Col-tech">
                <Card className="bg-danger text-white">
                    <Card.Body>
                        <SiAssemblyscript className="text-center" color="white" size="5em"/>
                    </Card.Body>
                </Card>
            </Col>
            <Col className="Col-tech">
                <Card className="bg-danger text-white">
                    <Card.Body>
                        <SiTypescript className="text-center" color="white" size="5em"/>
                    </Card.Body>
                </Card>
            </Col>
            <Col className="Col-tech">
                <Card className="bg-danger text-white">
                    <Card.Body>
                        <SiOwasp className="text-center" color="white" size="5em"/>
                    </Card.Body>
                </Card>
            </Col>
            <Col className="Col-tech">
                <Card className="bg-danger text-white">
                    <Card.Body>
                        <SiArduino className="text-center" color="white" size="5em"/>
                    </Card.Body>
                </Card>
            </Col>
            <Col className="Col-tech">
                <Card className="bg-danger text-white">
                    <Card.Body>
                        <SiSpring className="text-center" color="white" size="5em"/>
                    </Card.Body>
                </Card>
            </Col>
            <Col className="Col-tech">
                <Card className="bg-danger text-white">
                    <Card.Body>
                        <SiDocker className="text-center" color="white" size="5em"/>
                    </Card.Body>
                </Card>
            </Col>
        </Row>
        <Row className="Row-tech py-4 px-5 mx-5">
            <Col className="Col-tech">
                <Card className="bg-danger text-white">
                    <Card.Body>
                        <SiDotnet className="text-center" color="white" size="5em"/>
                    </Card.Body>
                </Card>
            </Col>
            <Col className="Col-tech">
                <Card className="bg-danger text-white">
                    <Card.Body>
                        <SiFlutter className="text-center" color="white" size="5em"/>
                    </Card.Body>
                </Card>
            </Col>
            <Col className="Col-tech">
                <Card className="bg-danger text-white">
                    <Card.Body>
                        <SiSocketdotio className="text-center" color="white" size="5em"/>
                    </Card.Body>
                </Card>
            </Col>
            <Col className="Col-tech">
                <Card className="bg-danger text-white">
                    <Card.Body>
                        <SiCloudflare className="text-center" color="white" size="5em"/>
                    </Card.Body>
                </Card>
            </Col>
            <Col className="Col-tech">
                <Card className="bg-danger text-white">
                    <Card.Body>
                        <SiKeras className="text-center" color="white" size="5em"/>
                    </Card.Body>
                </Card>
            </Col>
            <Col className="Col-tech">
                <Card className="bg-danger text-white">
                    <Card.Body>
                        <SiOracle className="text-center" color="white" size="5em"/>
                    </Card.Body>
                </Card>
            </Col>
        </Row>
        </div>
        </>
    }
}

export default Technology